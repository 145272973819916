import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import {
  BackgroundTexture,
  HeadingWithIcon,
  Layout,
  LiveMusic,
  SecondBanner,
  ThirdBanner,
  RugbyBallIcon,
  SEO,
  SideBySide,
  UpcomingEvents,
} from '../../components';
import { ContactFormWithMap } from '../../components/contact-form-with-map';
import { useGraphQL } from '../../hooks';

function EventsPage(): React.ReactElement {
  return (
    <Layout>
      <SEO title="Events Entertainment" />
      <LiveMusic />
      <SecondBanner />
      <UpcomingEvents />
      <ThirdBanner />
      <ContactFormWithMap background="blue" />
    </Layout>
  );
}

function LiveSport(): React.ReactElement {
  const { liveSport } = useGraphQL();
  return (
    <div id="live_sport" className="relative py-12 scroll-margin-top-24">
      <BackgroundTexture insetBottom="full" overflowBottom />
      <div className="relative">
        <SideBySide
          sideComponent={
            <GatsbyImage
              image={liveSport.childImageSharp.gatsbyImageData}
              className="flex-1 h-full"
              alt="Live sport on big screen"
              objectFit="contain"
            />
          }
        >
          <div className="w-full mx-auto max-w-prose">
            <div className="flex items-start">
              <HeadingWithIcon>
                <HeadingWithIcon.Heading>
                  Live
                  <br />
                  Sport
                  <br />
                  On screen!
                </HeadingWithIcon.Heading>
                <HeadingWithIcon.Icon hidden>
                  <RugbyBallIcon />
                </HeadingWithIcon.Icon>
              </HeadingWithIcon>
            </div>
            <h3 className="mt-8 heading-3">Every night from 6.00pm</h3>
            <div className="mt-5 prose">
              <p>
                Live sport all day every day in our famous Non-Thinkers bar.
              </p>
              <p>
                Don’t forget we also have live sport on the big screen every
                evening from 6pm.
              </p>
            </div>
          </div>
        </SideBySide>
      </div>
    </div>
  );
}

export { EventsPage as default };
